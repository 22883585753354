import { Slider } from "antd";
import { useEffect, useRef, useState } from "react";
import { Dropdown, ListGroup } from "react-bootstrap";
import Cropper from "react-easy-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import avatar from "../../../src/assets/avatar.png";
import { useDimensions } from "../../Logic/Dimensions";
import { useCoverPhoto } from "../../Logic/useCoverPhotoUpload";
import { useProfile } from "../../Logic/useProfile";
import { useProfileUpload } from "../../Logic/useProfilePhotoUpload";
import { currentTabSelection } from "../../store/slices/gallerySlice";
import getCroppedImg from "../../utils/Crop/imageCrop";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import Assets from "../Layouts/Assets";
import Icons from "../Layouts/Icons";
import CommonModal from "../Modals/commonModal";
import ProfileEdit from "../ProfileEdit";
import ShareModal from "../ShareModal";
import StaredImages from "./StaredImages";
import Style from "./UserProfile.module.scss";
import { useProfileshare } from "./useShareProfile";

const UserProfile = () => {
  const {
    inputFile,
    inputFileForm,
    onButtonClick,
    UploadCover,
    TriggerRemoveCoverPhoto,
    IsCoverPhotoUploading,
    IsCoverPhotoDeleting,
    CoverButtonLabel,
    handleDragOverCover,
    handleDragOutCover,
    handleDropCover,
    showCoverCroper,
    setCoverCroper,
    coverPhoto,
    uploadCoverPhoto,
    handleDropNew,
    handleDragOutCoverNew,
    handleDragCoverNew,
    setCoverPhoto,
  } = useCoverPhoto();

  const {
    IsProfilePhotoUploading,
    openEditProfileImage,
    setOpenEditProfileImage,
    saveImage,
    OpenDropDownBox,
    handleDragOver,
    handleDragOut,
    handleDrop,
    RemoveProfile,
    imageSrc,
    SetImageSrc,
  } = useProfileUpload();

  const dispatch = useDispatch();
  const [profileCrop, setProfileCrop] = useState({ x: 0, y: 0 });
  const [profileZoom, setProfileZoom] = useState(1);
  const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
  const [coverZoom, setCoverZoom] = useState(1);
  const [blob, setBlob] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [finalCoverPhoto, setFinalCoverPhoto] = useState();

  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [openStarredImageModal, setOpenStarredImageModal] = useState(false);
  const [tabLinkActive, setTabLinkActive] = useState("Overview");
  const { token, openShare, setOpenShare, handleShareProfile, handleDuration } =
    useProfileshare();

  useEffect(() => {
    if (!openEditProfileImage) {
      setProfileZoom(1);
    }
  }, [openEditProfileImage]);
  const {
    connections_store,
    capitalizeFirstLetter,
    triggeruseEffectGetProfileDetails,
  } = useProfile();

  const [floater, setFloater] = useState({
    floaterWidth: 121,
    leftValue: 0,
  });
  const location = useLocation();
  const windowDimensions = useDimensions();
  const { userdata } = useSelector((state) => state.userprofile);
  const { registerData } = useSelector((state) => state.auth);

  useEffect(() => {
    triggeruseEffectGetProfileDetails();
    dispatch(currentTabSelection("all"));
  }, []);

  useEffect(() => {
    if (location.pathname?.includes("/members")) {
      setTabLinkActive("Members");
    }
  }, [location.pathname]);

  const floaterRef = useRef();

  const handleFloater = (e, pathName) => {
    setTabLinkActive(pathName);
  };

  const getProfileImage = () => {
    if (Array.isArray(userdata?.profile_image) === false) {
      return userdata?.profile_image?.mediumThumbnail;
    } else {
      return avatar;
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          SetImageSrc(reader.result);
        },
        false
      );

      reader.readAsDataURL(file);
    } else {
      toast.warning("Please select valid image", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    // getBlob(croppedImage)
    setBlob(croppedImage);
  };

  const onCoverCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(coverPhoto, croppedAreaPixels);
    // getBlob(croppedImage)
    setFinalCoverPhoto(croppedImage);
  };

  let [cordX, cordY] = [50, 50];

  const checkDataError = !IsCoverPhotoUploading && userdata?.src?.length !== 0;

  return (
    <>
      {openStarredImageModal ? (
        <StaredImages
          openStarredImageModal={openStarredImageModal}
          setOpenStarredImageModal={setOpenStarredImageModal}
        />
      ) : (
        <div className={Style.user_profile}>
          <div className={Style.user_profile_header}>
            <figure
              className={Style.user_profile_cover_photo}
              onDragOver={handleDragOverCover}
              onDrop={handleDropCover}
              onDrag={handleDragOutCover}
            >
              <img
                src={
                  userdata?.src?.length !== 0
                    ? userdata?.src?.original
                    : Assets.cover_img
                }
                alt=""
              />

              {typeof userdata?.src === "object" && (
                <div className={Style.cover_photo_upload}>
                  <button className={`btn btn-white`} onClick={onButtonClick}>
                    {/* <form ref={inputFileForm}>
                      <input
                        type="file"
                        id="file"
                        accept="image/*"
                        onChange={UploadCover}
                        ref={inputFile}
                      />
                    </form> */}

                    {IsCoverPhotoUploading ? (
                      <span className="loading dark"></span>
                    ) : (
                      <Icons
                        icon={checkDataError ? "edit" : "icon-camera"}
                        size={18}
                      />
                    )}
                    <span>
                      {/* {CoverButtonLabel(
                        IsCoverPhotoUploading ? 3 : checkDataError ? 2 : 1
                      )} */}
                      {userdata?.src?.original
                        ? "Change Cover Photo"
                        : IsCoverPhotoUploading
                        ? "Uploading..."
                        : "Add Cover Photo"}
                    </span>
                  </button>
                  {userdata?.src.length !== 0 && (
                    <button
                      className="btn btn-remove"
                      disabled={IsCoverPhotoDeleting}
                      onClick={TriggerRemoveCoverPhoto}
                    >
                      {IsCoverPhotoDeleting ? (
                        <span className={"loading"}></span>
                      ) : (
                        <Icons icon={"icon-trash"} size={18} />
                      )}
                    </button>
                  )}
                </div>
              )}
            </figure>
            <div className={Style.user_profile_row}>
              {windowDimensions.width >= 768 ? (
                <div
                  className={Style.user_profile_profimg}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <figure>
                    <img
                      src={
                        userdata?.profile_image?.mediumThumbnail
                          ? userdata?.profile_image?.mediumThumbnail
                          : avatar
                      }
                      alt=""
                    />
                  </figure>
                  <div className={Style.profileImage_upload}>
                    <Dropdown className={`${Style.profile_photo_upload}`}>
                      <Dropdown.Toggle>
                        <Icons icon={"icon-camera"} size={16} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <button onClick={OpenDropDownBox}>Upload Image</button>
                        {userdata?.profile_image?.mediumThumbnail ? (
                          <button onClick={RemoveProfile}>Remove Image</button>
                        ) : (
                          <></>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className={Style.user_profile_details}>
                <div className={Style.user_profile_details_row}>
                  <div className={Style.user_profile_details_main}>
                    <div className={Style.user_profile_details_name_header}>
                      {windowDimensions.width < 768 ? (
                        <div className={Style.user_profile_profimg}>
                          <figure>
                            <img src={getProfileImage()} alt="" />
                          </figure>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className={Style.user_profile_details_name_wrap}>
                        <h1 className={Style.user_profile_details_name}>
                          {capitalizeFirstLetter(userdata?.full_name)}
                        </h1>
                        {windowDimensions.width < 1280 ? (
                          <div className={Style.user_profile_details_links}>
                            <button
                              className={`${Style.user_profile_details_btn} ${Style.user_profile_edit_profile}`}
                              onClick={() =>
                                setOpenEditProfile(!openEditProfile)
                              }
                            >
                              Edit profile
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {windowDimensions.width >= 1280 ? (
                      <>
                        <ListGroup as="ul">
                          {userdata?.family_name ? (
                            <ListGroup.Item
                              as="li"
                              title={userdata?.family_name}
                            >
                              {capitalizeFirstLetter(userdata?.family_name)}
                            </ListGroup.Item>
                          ) : (
                            ""
                          )}

                          {userdata?.dob_in_format ? (
                            <ListGroup.Item as="li">
                              Born {userdata?.dob_in_format}
                            </ListGroup.Item>
                          ) : (
                            ""
                          )}
                        </ListGroup>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {windowDimensions.width >= 1280 ? (
                    <>
                      <div className={Style.user_profile_details_links}>
                        <button
                          className={`${Style.user_profile_details_btn} ${Style.user_profile_edit_profile} ${Style.profile_share_btn}`}
                          onClick={() => handleShareProfile()}
                        >
                          <Icons icon={"share2"} size={14} />
                          <span>Share profile</span>
                        </button>
                        <button
                          className={`${Style.user_profile_details_btn} ${Style.user_profile_edit_profile}`}
                          onClick={() => setOpenEditProfile(!openEditProfile)}
                        >
                          Edit profile
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {windowDimensions.width >= 1280 ? (
                  <>
                    <div className={Style.left_filtered_category_list}>
                      {routeData.map((item, index) => {
                        return (
                          <div
                            onClick={(e) => handleFloater(e, item?.name)}
                            className={`${
                              tabLinkActive === item?.name
                                ? Style.left_filtered_category_list_active
                                : ""
                            }`}
                            ref={
                              tabLinkActive === item?.name ? floaterRef : null
                            }
                          >
                            <Link to={`${item.path}`} data-name={item?.name}>
                              {item?.name}
                            </Link>
                          </div>
                        );
                      })}

                      {/* {windowDimensions.width < 1440 ? ( */}
                      <div
                        onClick={(e) => handleFloater(e, "Members")}
                        className={`${
                          tabLinkActive === "Members"
                            ? Style.left_filtered_category_list_active
                            : ""
                        }`}
                        ref={tabLinkActive === "Members" ? floaterRef : null}
                      >
                        <Link to={"members"} data-name="Members">
                          Members
                        </Link>
                      </div>
                      {/* ) : (
                        <></>
                      )} */}
                      {/* <div
                        className={Style.floater}
                        style={{
                          width: `${floater.floaterWidth}px`,
                          left: `${floater.leftValue}px`,
                        }}
                      /> */}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              {windowDimensions.width < 1280 ? (
                <>
                  <ListGroup as="ul">
                    {userdata?.family_name ? (
                      <ListGroup.Item as="li">
                        {userdata?.family_name}
                      </ListGroup.Item>
                    ) : (
                      ""
                    )}
                    {userdata?.gender_id ? (
                      <ListGroup.Item as="li">
                        {userdata?.gender_id == 1
                          ? "Male"
                          : userdata?.gender_id == 2
                          ? "Female"
                          : "Other"}
                      </ListGroup.Item>
                    ) : (
                      ""
                    )}
                    {userdata?.dob_in_format ? (
                      <ListGroup.Item as="li">
                        Born {userdata?.dob_in_format}
                      </ListGroup.Item>
                    ) : (
                      ""
                    )}
                  </ListGroup>
                  <div className={Style.left_filtered_category_list}>
                    {routeData.map((item, index) => {
                      return (
                        <div
                          onClick={(e) => handleFloater(e, item?.name)}
                          className={`${
                            tabLinkActive === item?.name
                              ? Style.left_filtered_category_list_active
                              : ""
                          }`}
                          ref={tabLinkActive === item?.name ? floaterRef : null}
                        >
                          <Link to={`${item.path}`} data-name={item?.name}>
                            {item?.name}
                          </Link>
                        </div>
                      );
                    })}

                    <div
                      onClick={(e) => handleFloater(e, "Members")}
                      className={`${
                        tabLinkActive === "Members"
                          ? Style.left_filtered_category_list_active
                          : ""
                      }`}
                      ref={tabLinkActive === "Members" ? floaterRef : null}
                    >
                      <Link to={"members"} data-name="Members">
                        Members
                      </Link>
                    </div>
                    {/* <div
                      className={Style.floater}
                      style={{
                        width: `${floater.floaterWidth}px`,
                        left: `${floater.leftValue}px`,
                      }}
                    /> */}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <Outlet context={[openStarredImageModal, setOpenStarredImageModal]} />
        </div>
      )}

      <ProfileEdit
        openEditProfile={openEditProfile}
        setOpenEditProfile={setOpenEditProfile}
      />

      <CommonModal
        showModal={openEditProfileImage}
        hide={() => setOpenEditProfileImage(false)}
        className={`${Style.ProfileImageCrop__Modal}`}
        title={""}
        content={
          <>
            <div className={Style.file_upload_modal_form}>
              <h3 className={Style.edit_profile_info_title}>
                {userdata.profile_image !== undefined &&
                userdata.profile_image.length > 0
                  ? "Update profile picture"
                  : "Upload profile picture"}
              </h3>

              <div className={Style.ProfileImageCrop__contents}>
                {imageSrc ? (
                  <>
                    <figure className={Style.ProfileImageCrop__previewImage}>
                      <Cropper
                        image={imageSrc}
                        crop={profileCrop}
                        zoom={profileZoom}
                        aspect={1 / 1}
                        cropShape="round"
                        onCropChange={setProfileCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setProfileZoom}
                      />
                    </figure>

                    <div className={Style.ProfileImageCrop__controller}>
                      <Slider
                        defaultValue={profileZoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(e) => {
                          setProfileZoom(e);
                        }}
                        className={Style.zoom_slider}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className={Style.ProfileImageCrop__uploadBox}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onDragLeave={handleDragOut}
                  >
                    <label htmlFor="uploadImg">
                      <figure>
                        <img src={Assets.file_upload_image} alt="" />
                      </figure>
                      <p>
                        Drag and drop <br /> an image, <span>or Browse</span>
                      </p>
                      <form ref={inputFileForm}>
                        <input
                          type="file"
                          className="d-none"
                          name="file"
                          id="uploadImg"
                          onChange={handleImageChange}
                        />
                      </form>
                    </label>
                  </div>
                )}
                <p className={Style.ProfileImageCrop__uploadFileLimit}></p>
              </div>

              {imageSrc ? (
                <div className={Style.ProfileImageCrop__btnWrap}>
                  <button
                    className="btn btn-border-grey"
                    onClick={() => {
                      setOpenEditProfileImage(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      saveImage("profile", blob);
                    }}
                    className="btn btn-tertiary"
                  >
                    {!IsProfilePhotoUploading ? (
                      "Upload"
                    ) : (
                      <InfiniteDotLoader />
                    )}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        }
      />
      <ShareModal
        openShare={openShare}
        setOpenShare={setOpenShare}
        from="profile"
        secretToken={token}
        path={"profile"}
        handleDuration={handleDuration}
      />

      <CommonModal
        showModal={showCoverCroper}
        hide={() => {setCoverPhoto() 
          setFinalCoverPhoto()
          setCoverCroper(false)}}
        className={`${Style.ProfileImageCrop__Modal_lg}`}
        title={""}
        content={
          <>
            <div className={Style.file_upload_modal_form}>
              <h3 className={Style.edit_profile_info_title}>
                {userdata?.src?.original
                  ? "Change Cover Photo"
                  : "Add Cover Photo"}
              </h3>

              <div className={Style.ProfileImageCrop__contents}>
                <>
                  {!coverPhoto ? (
                    <div
                      className={Style.ProfileImageCrop__uploadBox}
                      onDragOver={handleDragCoverNew}
                      onDrop={handleDropNew}
                      onDragLeave={handleDragOutCoverNew}
                    >
                      <label htmlFor="uploadImg">
                        <figure>
                          <img src={Assets.file_upload_image} alt="" />
                        </figure>
                        <p>
                          Drag and drop <br /> an image, <span>or Browse</span>
                        </p>
                        <form ref={inputFileForm}>
                          <input
                            type="file"
                            id="uploadImg"
                            accept="image/*"
                            onChange={UploadCover}
                            ref={inputFile}
                            className="d-none"
                          />
                        </form>
                      </label>
                    </div>
                  ) : (
                    <>
                      <figure className={Style.ProfileImageCrop__previewImage}>
                        <Cropper
                          image={coverPhoto}
                          crop={coverCrop}
                          zoom={coverZoom}
                          aspect={16 / 9}
                          cropShape="square"
                          onCropChange={setCoverCrop}
                          onCropComplete={onCoverCropComplete}
                          onZoomChange={setCoverZoom}
                        />
                      </figure>

                      <div className={Style.ProfileImageCrop__controller}>
                        <Slider
                          defaultValue={coverZoom}
                          min={1}
                          max={3}
                          step={0.1}
                          onChange={(e) => {
                            setCoverZoom(e);
                          }}
                          className={Style.zoom_slider}
                        />
                      </div>
                    </>
                  )}
                </>

                <p className={Style.ProfileImageCrop__uploadFileLimit}></p>
              </div>

              {coverPhoto ? (
                <div className={Style.ProfileImageCrop__btnWrap}>
                  <button
                    className="btn btn-border-grey"
                    onClick={() => {
                      setCoverCroper(false);
                      setFinalCoverPhoto()
                      setCoverPhoto()
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      uploadCoverPhoto(finalCoverPhoto);
                    }}
                    className="btn btn-tertiary"
                  >
                    {!IsCoverPhotoUploading ? "Upload" : <InfiniteDotLoader />}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        }
      />
    </>
  );
};

let routeData = [
  {
    name: "Overview",
    path: "",
  },
];

export default UserProfile;
