import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Style from "./Footer.module.scss";
import Assets from "../../Assets";
import Icons from "../../Icons";
import { useTranslation } from "react-i18next";
import {
  showSignInState,
  showforgotpasswordState,
  logOut,
  changeScreen,
  authModalHandler,
} from "../../../../store/slices/authSlice";
import useAuthChecker from "../../../../Logic/useAuthChecker";
import { useRegister } from "../../../../Logic/useRegister";
import { useDispatch, useSelector } from "react-redux";
import { useFooter } from "./useFooter";
import ReactCountryFlag from "react-country-flag";

const Footer = () => {
  const { t } = useTranslation();
  const { isAuthCompleted } = useAuthChecker();

  const { showModal, setShowModal } = useRegister();
  const dispatch = useDispatch();

  const location = useLocation();

  const handleClickLogin = () => {
    setShowModal(true);
    dispatch(changeScreen("login"));
  };

  const handleScrollToTop = (route) => {
    location.pathname === `/${route ? route : ""}` &&
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const { registeredCountries } = useFooter();

  return (
    <div className="footer-section">
      <div className={Style.footer_top}>
        <Container>
          <Row>
            <Col className={Style.logo_col}>
              <figure className={`${Style.footer_logo} anim load-anim`}>
                <img
                  src={
                    localStorage.getItem("theme") == "light"
                      ? Assets.fpa_main_logo_dark
                      : Assets.fpa_main_logo
                  }
                  alt="Family Photos"
                />
              </figure>

              <div className={Style.flagContainer}>
                {registeredCountries?.map((item, index) => {
                  return item?.country_code ? (
                    <span className={Style.tooltip}>
                      <span className={Style.tooltiptext}>
                        {item?.country_name}
                      </span>
                      <ReactCountryFlag
                        id="my-anchor-element"
                        className="emojiFlag"
                        style={{
                          fontSize: "2em",
                          lineHeight: "2em",
                        }}
                        aria-label="United States"
                        countryCode={item?.country_code}
                        svg
                      />
                    </span>
                  ) : (
                    <></>
                  );
                })}
              </div>
            </Col>
            <Col className={Style.list_col_1}>
              <ul className={Style.footer_menu_list}>
                <h5
                  className={`${Style.footer_menu_list_title} anim load-anim visible`}
                >
                  {t("footer_link1")}
                </h5>
                <li
                  className={`${
                    location.pathname == "/" ? Style.active : ""
                  } anim load-anim visible`}
                >
                  <Link to={"/"} onClick={() => handleScrollToTop()}>
                    {t("footer_link2")}
                  </Link>
                </li>
                <li className="anim load-anim visible">
                  <a
                    onClick={() => {
                      if (isAuthCompleted === false) {
                        dispatch(authModalHandler(true));
                        dispatch(changeScreen("login"));
                      }
                    }}
                    href={
                      isAuthCompleted === true
                        ? "/dashboard"
                        : "javascript:void(0)"
                    }
                  >
                    {t("footer_link3")}
                  </a>
                </li>
                <li className="anim load-anim visible">
                  <a
                    onClick={() => {
                      if (isAuthCompleted === false) {
                        dispatch(authModalHandler(true));
                        dispatch(changeScreen("login"));
                      }
                    }}
                    href={
                      isAuthCompleted === true
                        ? "/dashboard/family-tree"
                        : "javascript:void(0)"
                    }
                  >
                    {t("footer_link4")}
                  </a>
                </li>
                <li
                  className={`${
                    location.pathname == "/contact" ? Style.active : ""
                  } anim load-anim visible`}
                >
                  <Link
                    to={"/contact"}
                    onClick={() => handleScrollToTop("contact")}
                  >
                    {t("footer_link5")}
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/quick-demo" ? Style.active : ""
                  } anim load-anim visible`}
                >
                  <Link
                    to={"/quick-demo"}
                    onClick={() => handleScrollToTop("contact")}
                  >
                    {t("get_a_demo")}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col className={Style.list_col_2}>
              <ul className={Style.footer_menu_list}>
                <h5
                  className={`${Style.footer_menu_list_title} anim load-anim visible`}
                  style={{ height: "21.59px" }}
                >
                  {/* {t("footer_link6")} */}
                </h5>
                <li
                  className={`${
                    location.pathname == "/terms-of-service-and-guidelines"
                      ? Style.active
                      : ""
                  } anim load-anim visible`}
                >
                  <Link
                    to={"/terms-of-service-and-guidelines"}
                    onClick={() =>
                      handleScrollToTop("terms-of-service-and-guidelines")
                    }
                  >
                    {t("footer_link7")}
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/privacy-policy" ? Style.active : ""
                  } anim load-anim visible`}
                >
                  <Link
                    to={"/privacy-policy"}
                    onClick={() => handleScrollToTop("privacy-policy")}
                  >
                    {t("footer_link8")}
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/cancellation-and-refund-policy"
                      ? Style.active
                      : ""
                  } anim load-anim visible`}
                >
                  <Link
                    to={"/cancellation-and-refund-policy"}
                    onClick={() =>
                      handleScrollToTop("cancellation-and-refund-policy")
                    }
                  >
                    {t("footer_link9")}
                  </Link>
                </li>
                <li
                  className={`${
                    location.pathname == "/faq" ? Style.active : ""
                  } anim load-anim visible`}
                >
                  <Link to={"/faq"} onClick={() => handleScrollToTop("faq")}>
                    {t("footer_link10")}
                  </Link>
                </li>
                {/* <li className={`${location.pathname == '/customer-support' ? Style.active : ''} anim load-anim`}>
                  <Link to={"/customer-support"} onClick={() => handleScrollToTop('customer-support')}>{t("footer_link11")}</Link>
                </li> */}
              </ul>
            </Col>
            {/* <Col className={Style.list_col_3}>
              <ul
                className={`${Style.footer_menu_list} ${Style.footer_socials}`}
              >
                <h5
                  className={`${Style.footer_menu_list_title} anim load-anim visible`}
                >
                  {t("footer_link12")}
                </h5>
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/"
                    rel="nofollow"
                  >
                    <Icons
                      icon={"fb"}
                      size={17}
                      className="anim load-anim visible"
                    />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/" rel="nofollow">
                    <Icons
                      icon={"twitter-x"}
                      size={17}
                      className="anim load-anim visible"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://in.linkedin.com/"
                    rel="nofollow"
                  >
                    <Icons
                      icon={"linkedin"}
                      size={17}
                      className="anim load-anim visible"
                    />
                  </a>
                </li>
              </ul>
            </Col> */}
          </Row>
        </Container>
      </div>
      <div className={Style.footer_bottom}>
        <Container>
          <div className={Style.footer_bottom_wrap}>
            <div className={`${Style.copyrights} anim load-anim visible`}>
              Copyright {new Date().getFullYear()} © familyphotosalbum.com. All
              rights reserved.
            </div>
            <div className={`${Style.designed_by} anim load-anim visible`}>
              Designed by{" "}
              <a
                target="_blank"
                href="https://webandcrafts.com/?utm_source=familyphotosalbum.com&utm_medium=referral&utm_campaign=familyphotosalbum.com_referral_wac_client"
              >
                Webandcrafts
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
