import React from "react";
import { useSelector } from "react-redux";
import Style from "./CheckoutPlanDetails.module.scss";
import parse from "html-react-parser";

const CheckoutPlanDetails = ({ className}) => {
  const {selectedPlan } = useSelector((state) => state.payment);
  return (
    <div className={`${Style.CheckoutPlanDetails} ${className}`}>
      <h3 className={Style.CheckoutPlanDetails__plan}>{selectedPlan?.name}</h3>
      <div className={Style.CheckoutPlanDetails__price}>
        <span>{selectedPlan?.plans_prices?.[0]?.symbol}</span>
        <span className={Style.price}>{selectedPlan?.price}</span>
        {selectedPlan?.plan_interval && `/ ${" "} ${selectedPlan?.plan_interval}`}
      </div>
      <p className={Style.CheckoutPlanDetails__description}>
        {selectedPlan?.description}
      </p>
      <ul className={Style.CheckoutPlanDetails__planFeatures}>
        {selectedPlan?.features? parse(selectedPlan?.features):""}
      </ul>
    </div>
  );
};

export default CheckoutPlanDetails;
