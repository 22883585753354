import { Col, Row } from "react-bootstrap";
import Style from "./UserProfileOverview.module.scss";
import { useState, useRef, useEffect } from "react";
import UserProfileGalleryImages from "../UserProfileGalleryImages";
import UserProfileFamilyMembers from "../UserProfileFamilyMembers";
import { useDimensions } from "../../../Logic/Dimensions";
import { useProfile } from "../../../Logic/useProfile";
import GalleryBlankPage from "../../BlankGallery";
import { useSelector } from "react-redux";
import Icons from "../../Layouts/Icons";
import { useOutletContext } from "react-router-dom";
import useGallery from "../../../Logic/useGallery";
import { useOverView } from "./useOverView";
import ImageListMasonry from "../../ImageListMasonry";
import { useProfileGallery } from "./useProfileGallery";
import ProfileGalleryActions from "./ProfileGalleryActions";

const UserProfileOverview = () => {
  const [openStarredImageModal, setOpenStarredImageModal] = useOutletContext();
  const { sections } = useSelector((state) => state.gallery);
  const breakpointColumnsObj = {
    default: 5,
    1599: 4,
    991: 3,
    575: 2,
    374: 1,
  };

  const { userdata, isReadMore, toggleReadMore, capitalizeFirstLetter } =
    useProfile();

  const { options, openStaredImages, closeReArrange, opemReArrange } =
    useOverView({ openStarredImageModal, setOpenStarredImageModal });

  const { profileGallery, loadMore, NodataContent, rearrangeItems } =
    useProfileGallery();

  return (
    <div className={Style.User_profile_overview}>
      <div className={Style.User_profile_overview_main_col}>
        {userdata?.bio != undefined && userdata?.bio !== "" && (
          <div className={Style.User_profile_overview_sec}>
            <h2 className={Style.User_profile_overview_title}>Bio</h2>
            <p className={Style.User_profile_overview_p}>
              {isReadMore && userdata?.bio.length > 0
                ? capitalizeFirstLetter(userdata?.bio?.slice(0, 400))
                : capitalizeFirstLetter(userdata?.bio)}
              {userdata?.bio.length > 400 && (
                <span onClick={toggleReadMore} className={Style.Read_or_hide}>
                  {isReadMore ? "...read more" : " show less"}
                </span>
              )}
            </p>
          </div>
        )}

        {/* gallery */}
        <div className={Style.User_profile_overview_sec}>
          <div
            className={`${Style.User_profile_overview_sec_head} ${
              !profileGallery?.resultData?.data?.length > 0
                ? Style.User_profile_overview_sec_errorWrap
                : ""
            }`}
          >
            <h2 className={Style.User_profile_overview_title}>
              Profile Gallery ( {profileGallery.resultData?.data?.length} )
            </h2>
            {profileGallery?.resultData?.data?.length > 0 ? (
              <ProfileGalleryActions
                data={profileGallery.resultData.data}
                options={options}
                closeReArrange={closeReArrange}
                opemReArrange={opemReArrange}
                openStaredImages={openStaredImages}
              />
            ) : (
              <div className={Style.errorMessage}>
                <p>Your profile gallery is empty!</p>
              </div>
            )}
          </div>

          <ImageListMasonry
            total={profileGallery?.resultData?.data?.length}
            Images={profileGallery?.resultData?.data}
            loadMore={{}}
            NodataContent={NodataContent}
            options={options}
            moveListItem={rearrangeItems}
            disableControls={false}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfileOverview;
