import { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import React from "react";
import ReactDOM from "react-dom";
import { useDimensions } from "../../Logic/Dimensions";
import { Viewer } from "@react-pdf-viewer/core";
import { useSocial } from "../../Logic/useSocial";
import Icons from "../Layouts/Icons";
import ShareModal from "../ShareModal";
import Style from "./pdfViewer.module.scss";
import { printPlugin } from "@react-pdf-viewer/print";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";

const PDFViewer = ({ url, data, handleClose }) => {
  const windowDimensions = useDimensions();
  const { fetchSocialDetails, secretToken, openShare, setOpenShare } =
    useSocial();

  const handleOpenShareModal = (id) => {
    setOpenShare(!openShare);
    fetchSocialDetails(id);
  };

  // Initialize plugins
  const printPluginInstance = printPlugin({
    printOptions:{
      pageSize:'A4',
      pageMargins: { top: 0, bottom: 0, left: 0, right: 0 },
      scale: 0.6,   
    }
  });
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return ReactDOM.createPortal(
    <>
      <div className={Style.viewer}>
        <div className={Style.pdf_header}>
          <div className={Style.pdf_header_left}>
            <button className={Style.pdf_header_btn} onClick={handleClose}>
              <Icons
                icon={"back-arrow"}
                size={windowDimensions.width >= 1279 ? 20 : 14}
              />
            </button>
          </div>
        </div>

        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <div
            className="rpv-core__viewer"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "50%",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                alignItems: "center",
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                display: "flex",
                padding: "4px",
              }}
            >
              <Toolbar />
            </div>
            <div
              style={{
                flex: 1,
                overflow: "hidden",
              }}
            >
              <Viewer
                fileUrl={url}
                plugins={[toolbarPluginInstance, printPluginInstance]}
              />
            </div>
          </div>
        </Worker>
      </div>
    </>,
    document.getElementById("slideshow-root")
  );
};

export default PDFViewer;
